import { Search, SearchProps } from '@paljs/ui/Search';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useState, useEffect } from 'react';
import SEO from '../../components/SEO';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import { Status } from '@paljs/ui/types';
import { Radio } from '@paljs/ui/Radio';
import { getAllGautras2 } from '../../actions/gautraAction';
import { koot_falan } from '../../helpers/utils';
import EditUser from './editUser';
import SelectGautra from '../family-tree/select-gautra';
import styled from 'styled-components';

import DataTable from 'react-data-table-component';
import { searchUser, searchUserByMobile, updateUser } from '../../actions/searchAction';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Collapse from '@material-ui/core/Collapse';
import { useLocation } from '@reach/router';
// Icons
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
// import Collapse from "@material-ui/icons/Collapse";
// import Collapse from "@material-ui/icons/ArrowDownward";
// import Typography from "@material-ui/icons/Typography";
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { navigate, Link } from 'gatsby';
import LoadingSpinner from '../loader';
import { setuid } from 'process';
import { margin } from '@mui/system';
import { Divider } from 'rsuite';
import { ControlPoint } from '@material-ui/icons';
import { partitionByVillageFatherMatch } from '../../helpers/searchUtils';

const SelectGautraStyled = styled(SelectGautra)`
  width: 80rem;
  z-index: 10;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input value={row[name]} name={name} onChange={(e) => onChange(e, row)} className={classes.input} />
      ) : name === 'name' ? (
        <Link to={`/family-tree?idOfUser=${row.id}`}>{row[name]}</Link>
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

export default function SearchPage() {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const nameFromURL = params.get('name') ?? '';
  const fatherNameFromURL = params.get('fatherName') ?? '';
  const kootFalanFromUrl = koot_falan(nameFromURL);
  const gautraFromURL = params.get('gautra') ?? '';
  const mobileFromRUL = params.get('mobile') ?? '';
  const [value, setValue] = useState('');
  const [isLoading, showLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showNameTemp, setShowNameTemp] = useState('');
  const [showEditableTable, setShowEditableTable] = useState(false);
  const [error, setError] = useState('');
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [currRow, setCurrRow] = useState('');
  const [search, setSearch] = useState('');
  const [mobile, setMobile] = useState(mobileFromRUL);
  const [uid, setUid] = useState('');
  const [name, setName] = useState(nameFromURL);
  const [fatherName, setFatherName] = useState(fatherNameFromURL);
  const [kootFalan, setKootFalan] = useState(kootFalanFromUrl);
  const [gautraObj, setGautraObj] = useState(gautraFromURL);
  const [subCaste, setSubCaste] = useState('');
  const [gender, setGender] = useState('male');
  const [gautraList, setGautraList] = useState([]);
  const classes = useStyles();
  const [previous, setPrevious] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [village, setNewVillage] = useState('');

  useEffect(() => {
    allGautra();
  }, []);
  const allGautra = async () => {
    const response = await getAllGautras2();

    setGautraList(response?.data?.gautras);
  };
  const onToggleEditMode = (id) => {
    console.log('--id--', id);
    console.log('--rows--', rows);
    setOpen(!open);
    setRows((state) => {
      return rows.map((row) => {
        if (row.uid === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  // TODO: Remove icon for edit for now
  // Code for edit is not written properly and niether tested for all fields
  const onEditModeDone = (id, rowDetail) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.uid === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      grow: 0.2,
      cell: (row, index, column, id) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      name: '',
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <>
              <IconButton aria-label="done" onClick={() => onEditModeDone(row.uid, row)}>
                <DoneIcon />
              </IconButton>
              <IconButton aria-label="revert" onClick={() => onRevert(row.uid)}>
                <RevertIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton aria-label="edit" onClick={() => onToggleEditMode(row.uid)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="whatsApp">
                <a href={`/whatsapp/templates?node_id=${row.uid}`} target="_blank">
                  {' '}
                  <WhatsAppIcon />{' '}
                </a>
              </IconButton>
            </>
          )}
        </>
      ),
    },
    {
      name: 'Uid',
      selector: (row) => row.uid,
      grow: 2,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.name} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            <a
              href={`/family-tree?idOfUser=${row.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'blue' }}
            >
              {row.name}
            </a>
          )}
        </>
      ),
    },
    {
      name: 'Husband/Father Name',
      selector: (row) => row.father_name,
      sortable: true,
      grow: 3,
      wrap: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.father_name} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : row.gender === 'female' ? (
            <div>
              D/o {row.father_name} <br /> {row?.husband_full_name ? `and W/o ${row.husband_full_name}` : ''}{' '}
            </div>
          ) : (
            <div>S/o {row.father_name}</div>
          )}
        </>
      ),
    },
    {
      name: 'subcaste(Gautra)',
      selector: (row) => row.gautra,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <>
              <Input value={row.subcaste} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
              <Input value={row.gautra} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
            </>
          ) : (
            <div>{row?.subcaste ? `${row.subcaste}(${row.gautra})` : `${row.gautra}`}</div>
          )}
        </>
      ),
    },
    {
      name: 'Village',
      selector: (row) => row.village,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.village} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            row.village
          )}
        </>
      ),
    },
    {
      name: 'Mobile No.',
      selector: (row) => row.mobile,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.mobile} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            row.mobile
          )}
        </>
      ),
    },
    {
      name: 'Registered Mobile No.',
      selector: (row) => row.registered_mobile,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input
              value={row.registered_mobile}
              onChange={(e) => onChangeOfUserField(e, row)}
              className={classes.input}
            />
          ) : (
            row.registered_mobile
          )}
        </>
      ),
    },
    {
      name: 'Pet Name',
      selector: (row) => row.pet_name,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.pet_name} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            row.pet_name
          )}
        </>
      ),
    },
    {
      name: 'Born',
      selector: (row) => row.born,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.born} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            row.born
          )}
        </>
      ),
    },
    {
      name: 'Alive',
      selector: (row) => row.alive,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.alive} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            row.alive
          )}
        </>
      ),
    },
    {
      name: 'Mukhiya',
      selector: (row) => row.is_mukhiya,
      sortable: true,
      cell: (row) => (
        <>
          {row.isEditMode ? (
            <Input value={row.is_mukhiya} onChange={(e) => onChangeOfUserField(e, row)} className={classes.input} />
          ) : (
            row.is_mukhiya
          )}
        </>
      ),
    },
  ];

  const onChangeOfUserField = async (e, row) => {
    console.log('--row--', row);
    if (!previous[row.uid]) {
      setPrevious((state) => ({ ...state, [row.uid]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    await updateUser(row, name, value);
    const { uid } = row;
    const newRows = rows.map((row) => {
      if (row.uid === uid) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };
  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.uid === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const submitHandle = async () => {
    let sentValue = {
      graph_node_id: uid,
      name,
      father_name: fatherName.trim(),
      koot_falan: name ? koot_falan(name).trim() : '',
      gautra: gautraObj?.gautra,
      subcaste: gautraObj?.subcaste ?? '',
      gender,
      mobile,
    };

    showLoader(true);
    try {
      let result;
      var response;
      if (mobile) {
        response = await searchUserByMobile(sentValue);
      } else {
        response = await searchUser(sentValue);
        // console.log('inside search User');
      }
      const searchResultsResponse = response?.search_results;
      const sortedSearchResults = partitionByVillageFatherMatch(
        searchResultsResponse,
        sentValue,
        searchResultsResponse?.input_village_details,
      );
      // console.log('sortedSearchResults', sortedSearchResults);
      // result = sortedSearchResults?.nameList?.map((item, index) => {
      //   return { ...item, seq: index + 1 };
      // });
      // console.log('result from row', result);
      const combinedResults = [
        ...sortedSearchResults?.nameListFatherMatch,
        ...sortedSearchResults?.fatherMatchedList,
        ...sortedSearchResults?.nameListVillageMatch,
        ...sortedSearchResults?.villageMatchedList,
        ...sortedSearchResults?.nameList,
        ...sortedSearchResults?.remainingSearchResultsSortedByVillageList,
      ];
      // TODO: Show results in partition later.

      // Update the state with the combined array
      setRows(combinedResults);
      setOriginalRows(combinedResults);
      setShowTable(true);
      showLoader(false);
    } catch (error) {
      setError('User Not Found!');
      showLoader(false);
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      do_search();
    }
  };

  const do_search = () => {
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(search) >= 0);
    setRows(temp_rows);
  };

  const showEditUserDiv = async (row) => {
    setCurrRow(row);
    // setRowEdit(true);
    // setEditRowUID(row.uid);
  };

  const cancelEditUserDiv = async (row) => {
    // setRowEdit(false);
  };

  const queryResolve = async (row: any) => {
    try {
      if (phoneNumber) {
        const response = updateUser(row, phoneNumber);
        let regPhoneNumberCell = document.getElementById(`row-${row.uid}`);
        if (regPhoneNumberCell) {
          let regMobCell = regPhoneNumberCell.querySelector(`#cell-8-${row.uid}`);
          if (regMobCell) regMobCell.innerHTML = phoneNumber;
        }
      }
    } catch (error) {
      setError('Something went wrong!');
      showLoader(false);
    }
  };

  const handleChangeId = (evnt: any) => {
    setName('');
    setFatherName('');
    setMobile('');
    setGautraObj('');
    setSubCaste('');
    setGender('');
    setKootFalan('');
    setUid(evnt.target.value);
  };
  const handleChangeName = (evnt: any) => {
    let name = evnt.target.value.trim();
    let kootFalanName = koot_falan(name);
    setName(name);
    // setKootFalan('पवन');
    setKootFalan(kootFalanName);
  };
  const handleChangeFatherName = (evnt: any) => {
    let fatherName = evnt.target.value.trim();
    let kootFalanFatherName = koot_falan(fatherName);
    setFatherName(fatherName);
    // setKootFalan('पवन');
    // setKootFalan(kootFalanFatherName);
  };
  const handleChangeGautra = (evnt: any) => {
    setGautraObj(evnt.target.value);
  };
  const handleChangeSubCaste = (evnt: any) => {
    setSubCaste(evnt.target.value);
  };
  const handleChangemobile = (event: any) => {
    setName('');
    setFatherName('');
    setUid('');
    setGautraObj('');
    setSubCaste('');
    setGender('');
    setKootFalan('');
    setMobile(event.target.value);
  };
  const onChangeGender = (evnt: any) => {
    console.log('--evnt--', evnt);

    setGender(evnt);
  };

  return (
    <>
      {/* <datalist id="defaultgautras">
        {gautraList.map((item) => {
          return <option value={`${item.name}`}></option>
        })}
      </datalist> */}
      <SEO title="Search User" />
      <Card status="Info">
        <CardHeader>Search Users</CardHeader>
        <CardBody>
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center' }}>
              <InputGroup style={{ width: '400px', padding: '5px' }} fullWidth>
                <input
                  type="text"
                  name="name"
                  onChange={(evnt) => handleChangeName(evnt)}
                  placeholder="Enter Name"
                  value={name}
                  disabled={uid || mobile ? true : false}
                />
              </InputGroup>
              <div style={{ width: '400px', padding: '5px' }}>
                <SelectGautra
                  setGautraObj={(value) => {
                    setGautraObj(value);
                  }}
                  gautraObj={gautraObj}
                  disabled={uid || mobile ? true : false}
                />
              </div>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center' }}>
              <InputGroup style={{ width: '400px', padding: '5px' }} fullWidth>
                <input
                  type="text"
                  name="fatherName"
                  onChange={(evnt) => handleChangeFatherName(evnt)}
                  placeholder="Enter Father Name"
                  value={fatherName}
                  disabled={uid || mobile ? true : false}
                />
              </InputGroup>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center' }}>
              <InputGroup style={{ width: '600px', padding: '5px' }} fullWidth>
                <input
                  type="text"
                  name="id"
                  value={uid}
                  onChange={(evnt) => handleChangeId(evnt)}
                  placeholder="Enter Uid"
                  disabled={mobile ? true : false}
                />
              </InputGroup>
              <InputGroup style={{ width: '600px', padding: '5px' }} fullWidth>
                <input
                  type="tel"
                  name="mobile"
                  value={mobile}
                  onChange={(evnt) => handleChangemobile(evnt)}
                  placeholder="Enter Mobile No."
                  disabled={uid ? true : false}
                />
              </InputGroup>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center' }}>
              <Radio
                name="radio"
                onChange={(evnt) => onChangeGender(evnt)}
                disabled={uid || mobile ? true : false}
                options={[
                  {
                    value: 'male',
                    label: 'Male',
                    status: 'Info',
                    checked: true,
                  },
                  {
                    value: 'female',
                    label: 'Female',
                    status: 'Danger',
                  },
                ]}
              />
            </Col>
          </Row>
          <Row
            breakPoint={{ xs: 12, sm: 12, md: 2, lg: 2 }}
            style={{ margin: '10px', display: 'flex', justifyContent: 'center' }}
          >
            <Button status="Danger" type="button" shape="SemiRound" onClick={() => submitHandle()}>
              Search
            </Button>
          </Row>
        </CardBody>
        <CardFooter>
          <footer> {name ? `Name You are Searching: ${name} (${kootFalan})` : ''}</footer>
        </CardFooter>
      </Card>
      <Row>{showNameTemp}</Row>

      {showEditableTable ? (
        <Row>
          <Col>
            <Card status="Warning">
              <CardHeader>Edit Users</CardHeader>
              <CardBody>
                <EditUser userData={currRow} />
              </CardBody>
              <CardFooter>
                <footer> {name ? `Name You are Searching: ${name}` : ''}</footer>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {isLoading ? (
        <LoadingSpinner message={`Loading Data for Users `} />
      ) : showTable ? (
        <DataTable
          title="List"
          columns={tableColumns}
          data={rows}
          pagination
          paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
          paginationPerPage={50}
          striped
          dense
          noHeader
          highlightOnHover
        />
      ) : (
        ''
      )}
    </>
  );
}
