import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

export default function EditUser(row) {
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [previous, setPrevious] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const CustomTableCell = ({ row, name, onChange }) => {
    const classes = useStyles();
    const { isEditMode } = row;
    return (
      <TableCell align="left" className={classes.tableCell}>
        {isEditMode ? (
          <Input value={row[name]} name={name} onChange={(e) => onChange(e, row)} className={classes.input} />
        ) : (
          row[name]
        )}
      </TableCell>
    );
  };

  const onToggleEditMode = (id) => {
    console.log('--id--', id);
    console.log('--rows--', rows);
    setOpen(!open);
    setRows((state) => {
      return rows.map((row) => {
        if (row.uid === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onEditModeDone = (id, rowDetail) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.uid === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    // console.log('--row--', row)
    if (!previous[row.uid]) {
      setPrevious((state) => ({ ...state, [row.uid]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { uid } = row;
    const newRows = rows.map((row) => {
      if (row.uid === uid) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.uid === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  return (
    <>
      <CustomTableCell {...{ row, name: 'name', onChange }} />
      <CustomTableCell {...{ row, name: 'husband_full_name', onChange }} />
      <CustomTableCell {...{ row, name: 'father_name', onChange }} />
      <CustomTableCell {...{ row, name: 'gautra', onChange }} />
      <CustomTableCell {...{ row, name: 'subcaste', onChange }} />
      <CustomTableCell {...{ row, name: 'village', onChange }} />
      <CustomTableCell {...{ row, name: 'mobile', onChange }} />
      <CustomTableCell {...{ row, name: 'registered_mobile', onChange }} />
    </>
  );
};
